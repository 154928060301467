@import '../../@pages/styles/mixins';

.modal-view-item-user,
.modal-view-item,
.items-table-page {

  .icon-active {
    display: inline-block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border-style: solid;
    border-width: 1px;

    &::after {
      content: '';
      display: inline-block;
      width: 12px;
      height: 12px;
      margin: 2px 0 0 2px;
      border-radius: 50%;
    }

    &.icon-active-on {
      border-color: #00D0B3;
    }

    &.icon-active-on::after {
      background-color: #00D0B3;
    }

    &.icon-active-off {
      border-color: #FF5254;
    }

    &.icon-active-off::after {
      background-color: #FF5254;
    }
  }

  .btn {
    border: 0;
    padding: 0 9px;

    &.btn-icon {
      width: 30px;
      height: 30px;
      padding: 0;
      text-align: center;
    }

    &:not(.disabled):focus,
    &:not(.disabled):active,
    &:not(.disabled):hover {
      border: 0;
      padding: 0 9px;
    }

    &.btn-success {
      background: #00D0B3;
      box-shadow: 0 0 10px rgba(0, 208, 179, 0.25);
      border-radius: 5px;
      color: #fff;

      &:not(.disabled):focus,
      &:not(.disabled):active,
      &:not(.disabled):hover {
        background: #00D0B3;
      }
    }

    &.btn-danger {
      background: #FF5254;
      box-shadow: 0 0 10px rgba(255, 82, 84, 0.25);
      border-radius: 5px;
      color: #fff;

      &:not(.disabled):focus,
      &:not(.disabled):active,
      &:not(.disabled):hover {
        background: #FF5254;
      }
    }
  }

  .cdk-overlay-container {
    z-index: 1004;
  }

  .custom-input {
    border: 1px solid transparent;
    box-sizing: border-box;
    background: #F4F7FA;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.18);
    border-radius: 5px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    padding: 8px 10px;
    height: 30px;
    color: #000000;
    @include placeholder(#BBCBD7, 1);

    &:focus {
      border-color: #00D0B3;
      outline: none;
    }

  }

  .input-control,
  .textarea-control,
  .select-control {
    @extend .custom-input;

    &.ng-touched.ng-invalid {
      border-color: #FF5254;
    }

    &.input-date {
      padding-right: 30px;
    }

    &.input-search {
      border-radius: 20px;
      padding-left: 40px;
    }
  }

  .custom-select-control {
    width: 100%;



    &:not(.pg-select-open) {
      .pg-select-selection__rendered {
        &::after {
          display: none;
        }
      }
    }

    .pg-select-selection {
      @extend .custom-input;
      padding: 0;



    }

    &.ng-touched.ng-invalid {
      .pg-select-selection {
        border-color: #FF5254;
      }
    }

    .pg-select-selection--multiple {
      min-height: 30px;

      .pg-select-selection__choice__content {
        max-width: 100px;
      }

      .pg-select-selection__placeholder {
        left: 6px;
        top: 15px;
      }

      .pg-select-selection__rendered>ul>li {
        margin-top: 4px;
      }

      .pg-select-selection__rendered>ul {
        display: flex;
      }
    }

    .pg-select-selection__rendered {
      margin-left: 8px;
      margin-right: 20px;
      line-height: 30px;
      min-height: 28px;
    }

    .pg-select-selection-selected-value {
      float: unset;
      font-size: 12px;
    }

    .pg-select-arrow {
      right: 10px;
      top: 15px;
      font-family: unset;
      font-size: unset;
      line-height: 20px;

      &::before {
        content: '';
        display: inline-block;
        width: 10px;
        height: 6px;
        background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L5 5L9 0.999999' stroke='%23BBCBD7' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
}

/* items-table-page */
.items-list-table-loading {
  text-align: center;
  position: relative;
  top: -35px;
  pointer-events: none;
}

.items-table-page {
  &>.card {
    border-radius: 5px;
    border: 0;
    box-shadow: none;
  }

  &>.card .card-header {
    &.h-auto {
      min-height: 0;
    }
  }

  .button-item-add {
    display: block;
    width: 50px;
    height: 50px;
    padding: 0;
    line-height: 1;
    background: #00D0B3;
    box-shadow: 0 0 10px rgba(0, 208, 179, 0.25);
    border-radius: 50%;
    border: 0;
    color: #fff;
    font-size: 16px;
    font-weight: 100;
    text-align: center;
    position: absolute;
    top: -10px;
    right: -10px;

    &:hover {
      background: #00baa0;
    }
  }

  /* filter-form */
  .filter-form {
    .input-control.input-search {
      width: 100%;
    }

    .form-field {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: relative;

      .form-control-group {
        display: flex;
        flex-direction: column;
        position: relative;

        .form-control-inline {
          display: flex;
          width: 100%;
          align-items: center;

          .input-control,
          .textarea-control,
          .select-control {
            width: 100%;
          }
        }

      }

      .icon-calendar {
        position: absolute;
        top: 9px;
        right: 9px;
        display: inline-block;
        width: 12px;
        height: 12px;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg width='13' height='12' viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.98556 12H10.6809C11.5925 12 12.3333 11.2867 12.3333 10.4089V2.47999C12.3333 1.60221 11.5925 0.888872 10.6809 0.888872H10.0256V0.444436C10.0256 0.199989 9.81785 0 9.564 0C9.31015 0 9.10247 0.199989 9.10247 0.444436V0.888872H3.56404V0.444436C3.56404 0.199989 3.35632 0 3.10247 0C2.84862 0 2.64094 0.199989 2.64094 0.444436V0.888872H1.98556C1.07403 0.888872 0.333252 1.60221 0.333252 2.47999V10.4089C0.333252 11.2867 1.07403 12 1.98556 12V12ZM1.25635 2.47999C1.25635 2.09333 1.58404 1.77778 1.98556 1.77778H2.64094V2.22222C2.64094 2.46667 2.84862 2.66665 3.10247 2.66665C3.35632 2.66665 3.564 2.46667 3.564 2.22222V1.77778H9.10247V2.22222C9.10247 2.46667 9.31015 2.66665 9.564 2.66665C9.81785 2.66665 10.0255 2.46667 10.0255 2.22222V1.77778H10.6809C11.0824 1.77778 11.4101 2.09333 11.4101 2.47999V3.77778H1.25635V2.47999ZM1.25635 4.66665H11.4102V10.4089C11.4102 10.7955 11.0825 11.1111 10.681 11.1111H1.98556C1.58404 11.1111 1.25635 10.7955 1.25635 10.4089V4.66665Z' fill='%2300D0B3'/%3E%3C/svg%3E%0A");
      }

      .icon-search {
        position: absolute;
        left: 20px;
        top: 9px;
        display: inline-block;
        width: 12px;
        height: 12px;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.13177 10.263C6.27036 10.2628 7.37615 9.88161 8.27305 9.18024L11.093 12L12 11.093L9.18009 8.27325C9.88186 7.37631 10.2633 6.27033 10.2635 5.13149C10.2635 2.30212 7.9613 0 5.13177 0C2.30224 0 0 2.30212 0 5.13149C0 7.96087 2.30224 10.263 5.13177 10.263ZM5.13177 1.28287C7.2544 1.28287 8.9806 3.00898 8.9806 5.13149C8.9806 7.25401 7.2544 8.98012 5.13177 8.98012C3.00914 8.98012 1.28294 7.25401 1.28294 5.13149C1.28294 3.00898 3.00914 1.28287 5.13177 1.28287Z' fill='%23BBCBD7'/%3E%3C/svg%3E%0A");
      }
    }
  }

  /* items-list-table */
  .items-list-table-responsive {
    width: 100%;

    @media (max-width: 992px) {
      min-width: 100%;
      overflow: hidden;
      overflow-x: auto;
    }

    .items-list-table {
      min-width: 960px;
    }
  }

  .items-list-table {
    box-shadow: 0 0 10px rgba(187, 203, 215, 0.5);
    border-radius: 5px;

    .datatable-scroll {
      display: block !important;
    }

    .empty-row {
      height: 0;
      visibility: hidden;
    }

    .datatable-header-inner {
      width: 100% !important;

      .datatable-row-center {
        width: 100%;
      }
    }

    .datatable-header,
    .items-list-table-header {
      background: #F4F7FA;
      display: flex;
      flex-direction: row;
      width: 100%;
      padding: 0;
      border-bottom: 1px solid #ECEEF1;

      .items-list-table-cell {
        width: 100%;
        height: auto;
        padding: 10px 0 10px 20px !important;
        font-style: normal;
        font-weight: 400;
        font-family: inherit;
        font-size: 12px !important;
        line-height: 14px;
        color: #889099;
        text-transform: none !important;
        text-align: left;

        &.actions {
          padding: 0;
          margin: 0;
        }

        &.items-list-table-cell-id {
          max-width: 60px;
        }

        .sort-btn {
          display: inline-block !important;
          min-width: 14px !important;
          height: 14px !important;
          margin: 0;
          padding: 0;
          background-color: transparent;
          border: none;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #889099;
          cursor: pointer;
          text-align: left;
          white-space: nowrap;

          &:focus {
            outline: none;
          }

          &:before {
            display: none !important;
          }

          &:after {
            content: '' !important;
            display: inline-block !important;
            width: 14px !important;
            height: 14px !important;
            margin: 0 0 0 5px;
            padding: 0;
            background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 14C10.86 14 14 10.86 14 7C14 3.13997 10.86 0 7 0C3.13997 0 0 3.14051 0 7C0 10.8595 3.13997 14 7 14ZM7 1.08443C10.2614 1.08443 12.9156 3.73803 12.9156 7C12.9156 10.262 10.2614 12.9156 7 12.9156C3.73803 12.9156 1.08443 10.262 1.08443 7C1.08443 3.73803 3.73857 1.08443 7 1.08443Z' fill='%23889099'/%3E%3Cpath d='M3.90395 6.25174L6.61503 8.96282C6.8265 9.1732 7.16808 9.1732 7.37956 8.96282L10.0906 6.25174C10.2647 6.04896 10.2647 5.74911 10.0906 5.54632C9.89598 5.31913 9.5533 5.29257 9.32611 5.48723L7 7.81331L4.66848 5.48721C4.457 5.27682 4.11543 5.27682 3.90395 5.48721C3.69357 5.69868 3.69357 6.04029 3.90395 6.25174Z' fill='%23889099'/%3E%3C/svg%3E%0A");
            background-position: center;
            background-repeat: no-repeat;
            background-size: auto;
          }

          &.sort-asc,
          &.sort-desc {
            color: #00D0B3;

            &:after {
              background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 14C10.86 14 14 10.86 14 7C14 3.13997 10.86 0 7 0C3.13997 0 0 3.14051 0 7C0 10.8595 3.13997 14 7 14ZM7 1.08443C10.2614 1.08443 12.9156 3.73803 12.9156 7C12.9156 10.262 10.2614 12.9156 7 12.9156C3.73803 12.9156 1.08443 10.262 1.08443 7C1.08443 3.73803 3.73857 1.08443 7 1.08443Z' fill='%2300D0B3'/%3E%3Cpath d='M3.90395 6.25174L6.61503 8.96282C6.8265 9.1732 7.16808 9.1732 7.37956 8.96282L10.0906 6.25174C10.2647 6.04896 10.2647 5.74911 10.0906 5.54632C9.89598 5.31913 9.5533 5.29257 9.32611 5.48723L7 7.81331L4.66848 5.48721C4.457 5.27682 4.11543 5.27682 3.90395 5.48721C3.69357 5.69868 3.69357 6.04029 3.90395 6.25174Z' fill='%2300D0B3'/%3E%3C/svg%3E%0A");
            }
          }

          &.sort-desc {
            &:after {
              transform: rotate(180deg);
            }
          }
        }
      }
    }

    .items-list-table-body {
      display: flex;
      flex-direction: column;
      background: #FFFFFF;
    }

    .items-list-item-container {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;
      border-bottom: 1px solid #ECEEF1;
      padding: 0;

      .datatable-row-center {
        width: 100%;
      }

      .datatable-body-cell,
      .items-list-table-cell {
        width: 100%;
        padding: 20px !important;
        font-weight: 400;
        font-size: 12px !important;
        line-height: 14px;
        color: #000000;
        text-align: left;

        .datatable-body-cell-label {
          display: inline-block !important;
          height: auto !important;
        }

        &.check,
        &.items-list-table-cell-id {
          max-width: 47px;
        }

        &.actions {
          text-align: right;
          white-space: nowrap;
        }
      }
    }

    .items-list-table-expand {
      white-space: nowrap;
      cursor: pointer;

      i {
        font-size: 15px;
        color: #00D0B3;
      }
    }

    .items-list-table-image-cell {
      margin: -10px 0 -10px 0;
    }

    .items-list-table {
      border-radius: 0;

      .items-list-table-body {
        background: transparent;
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        box-shadow: inset 0 5px 10px rgba(0, 0, 0, 0.1), inset 0px -5px 10px rgba(0, 0, 0, 0.1);
        z-index: 111;
      }
    }

    .datatable-row-detail {
      height: 0;
      overflow: hidden;
      transition: height 300ms;
    }
  }

  .loading-container {
    height: 80px;
  }

  /* items-table-page-header-nav */
  .items-table-page-header-nav {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    background: #FFFFFF;
    box-shadow: 0 0 5.48571px rgba(0, 0, 0, 0.18);
    border-radius: 5px;

    li {
      margin: 0;
      padding: 0;
      flex-grow: 1;

      a {
        display: block;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 1;
        padding: 10px;
        opacity: 1;
        text-align: center;

        &.active {
          background: #00D0B3;
          box-shadow: 0 0 15px rgba(0, 208, 179, 0.25);
          border-radius: 5px;
          color: #fff;
        }
      }
    }
  }
}

/* modal-view-item */
.modal-view-item-backdrop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1002;
}

/* modal-view-item */
.modal-view-item {
  display: flex;
  flex-direction: column;
  width: 500px;
  height: 100%;
  min-height: 250px;
  position: fixed;
  left: auto;
  right: 0;
  top: 0;
  margin: 0 0 0 -250px;
  background: #FFFFFF;
  box-shadow: -3px 0px 10px rgba(0, 0, 0, 0.25);
  z-index: 1002;

  .modal-content {
    height: 100%;

    &>* {
      display: block;
      height: 100%;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  @media (max-width: 576px) {
    width: 100%;
    margin: 0;
    left: 0;
    top: 50px;
    height: calc(100% - 50px);
  }

  .modal-view-item-close {
    width: 30px;
    height: 30px;
    padding: 2px 0 0 0;
    line-height: 1;
    border-radius: 50%;
    background-color: #444444;
    border: 0;
    color: #fff;
    text-align: center;
    font-size: 12px;
    position: absolute;
    top: 10px;
    left: -45px;
    cursor: pointer;

    &:hover {
      background-color: #000;
    }

    @media (max-width: 576px) {
      left: 10px;
      top: -40px;
    }
  }

  .modal-view-item-header {
    height: 40px;
    background-color: #2B303A;
    color: #fff;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 40px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
  }

  .modal-view-item-body {
    flex-grow: 1;
    padding: 20px;
    overflow: hidden;
    overflow-y: auto;

    .modal-view-item-label {
      display: inline-block;
      margin-bottom: 5px;
      font-size: 12px;
      color: #808080;
    }

    input.custom-input {
      width: 100%;
      background: #FFFFFF;
      box-shadow: 0 0 10px rgba(187, 203, 215, 0.5);
      border-radius: 5px;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;

      &:focus {
        border: 1px solid #00D0B3;
        outline: none;
      }

      &.ng-touched.ng-invalid {
        border: 1px solid #db2c2c;
      }
    }
  }

  .modal-view-item-footer {
    padding: 20px;

    .btn {
      display: block;
      width: 100%;
      background: #BBCBD7;
      box-shadow: 0 0 10px rgba(187, 203, 215, 0.25);
      color: #fff;
      border-radius: 5px;
      font-weight: 400;
      font-size: 12px;

      &:hover,
      &:focus,
      &:active {
        background: #BBCBD7;
        box-shadow: 0 0 10px rgba(187, 203, 215, 0.25);
        color: #fff;
      }
    }
  }
}

/* modals */
.modal {
  .modal-dialog.modal-view-item-user {
    top: 100%;
    transition: top 300ms !important;
    transition-timing-function: linear;
  }

  &.show {
    .modal-dialog.modal-view-item-user {
      top: 60px;
    }
  }

  .modal-dialog.modal-view-item {
    top: 0;
    right: -500px;
    transition: right 300ms, top 300ms !important;
    transition-timing-function: linear;
  }

  &.show {
    .modal-dialog.modal-view-item {
      top: 0;
      right: 0;

      @media (max-width: 576px) {
        top: 50px;
      }
    }
  }

  .modal {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

/* modal-view-item-user */
.modal-view-item-user {
  position: fixed;
  right: 0;
  top: 60px;
  width: calc(100% - 72px) !important;
  max-width: calc(100% - 72px) !important;
  height: calc(100% - 60px) !important;
  margin: 0 !important;
  background-color: #EEF1F4;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px 10px 0 0;
  z-index: 1002;

  @media (max-width: 992px) {
    width: 100% !important;
    max-width: 100% !important;
  }

  form {
    display: block;
    height: 100%;
  }

  .modal-content {
    display: block;
    border: 0 !important;
    background-color: transparent !important;
    height: 100%;
  }

  .modal-view-item-close {
    width: 30px;
    height: 30px;
    padding: 2px 0 0 0;
    line-height: 1;
    border-radius: 50%;
    background-color: #444444;
    border: 0;
    color: #fff;
    text-align: center;
    font-size: 12px;
    position: absolute;
    top: -15px;
    right: 15px;
    cursor: pointer;

    &:hover {
      background-color: #000;
    }
  }

  .modal-view-item-header {
    padding: 50px 100px 0 100px;
    margin: 0 0 30px 0;

    @media (max-width: 992px) {
      padding-left: 40px;
      padding-right: 40px;
    }
  }

  .modal-view-item-body {
    height: 100%;
    padding: 0 50px 50px 50px;

    @media (max-width: 992px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  /* user-view */
  .user-view {
    height: calc(100% - 150px);
    padding: 0 50px;
    overflow: hidden;
    overflow-y: auto;

    @media (max-width: 992px) {
      height: calc(100% - 120px);
      padding-left: 20px;
      padding-right: 20px;
    }

    .user-view-vertical-separator {
      width: 1px;
      height: 100%;
      margin: 0 auto;
      background: linear-gradient(180deg, rgba(204, 204, 204, 0) 0%, #CCCCCC 50%, rgba(204, 204, 204, 0) 100%);
    }

    .user-view-photo-container {
      width: 690px;
      height: 100%;
      max-width: 100%;
      margin: 0 auto;
      position: relative;
      background: url('/assets/img/icon-big-photo.jpg') no-repeat center center #f4f4f2;
      background-size: contain;
      border: 1px dashed #BBCBD7;
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.18);
      border-radius: 5px;
      cursor: pointer;

      @media (max-width: 992px) {
        width: 100%;
        height: 300px;
      }

      .user-view-photo-edit {
        width: 100px;
        height: 100px;
        position: absolute;
        left: 50%;
        top: 50%;
        margin: - 50px 0 0 -50px;
        background: rgba(0, 208, 179, 0.5);
        box-shadow: 0 0 10px rgba(0, 208, 179, 0.25);
        backdrop-filter: blur(1.5px);
        border-radius: 50%;
        text-align: center;
        color: #fff;
        font-size: 40px;
        line-height: 100px;
        pointer-events: none;
        opacity: 0;
        transition: opacity 500ms;
      }

      &:hover {
        .user-view-photo-edit {
          opacity: 1;
        }
      }
    }

    .user-view-body {
      background: #FFFFFF;
      box-shadow: 0 0 10px rgba(187, 203, 215, 0.5);
      border-radius: 5px;
      padding: 20px;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;

      h4 {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: #00D0B3;
        margin: 0 0 15px 0;
      }

      .user-view-label {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #8A9AAF;
        margin-bottom: 5px;
      }

      input.form-control {
        font-size: 15px;
        line-height: 18px;
        height: 30px;
        background: #FFFFFF;
        box-shadow: 0 0 10px rgba(187, 203, 215, 0.5);
        border: 1px solid #fff;
        border-radius: 5px;

        &:focus {
          border-color: #00D0B3;
        }

        &.ng-touched.ng-invalid {
          border-color: #FF5254;
        }
      }

      input.form-control~.icon-calendar {
        position: absolute;
        top: 9px;
        right: 9px;
        display: inline-block;
        width: 12px;
        height: 12px;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg width='13' height='12' viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.98556 12H10.6809C11.5925 12 12.3333 11.2867 12.3333 10.4089V2.47999C12.3333 1.60221 11.5925 0.888872 10.6809 0.888872H10.0256V0.444436C10.0256 0.199989 9.81785 0 9.564 0C9.31015 0 9.10247 0.199989 9.10247 0.444436V0.888872H3.56404V0.444436C3.56404 0.199989 3.35632 0 3.10247 0C2.84862 0 2.64094 0.199989 2.64094 0.444436V0.888872H1.98556C1.07403 0.888872 0.333252 1.60221 0.333252 2.47999V10.4089C0.333252 11.2867 1.07403 12 1.98556 12V12ZM1.25635 2.47999C1.25635 2.09333 1.58404 1.77778 1.98556 1.77778H2.64094V2.22222C2.64094 2.46667 2.84862 2.66665 3.10247 2.66665C3.35632 2.66665 3.564 2.46667 3.564 2.22222V1.77778H9.10247V2.22222C9.10247 2.46667 9.31015 2.66665 9.564 2.66665C9.81785 2.66665 10.0255 2.46667 10.0255 2.22222V1.77778H10.6809C11.0824 1.77778 11.4101 2.09333 11.4101 2.47999V3.77778H1.25635V2.47999ZM1.25635 4.66665H11.4102V10.4089C11.4102 10.7955 11.0825 11.1111 10.681 11.1111H1.98556C1.58404 11.1111 1.25635 10.7955 1.25635 10.4089V4.66665Z' fill='%2300D0B3'/%3E%3C/svg%3E%0A");
      }

      .user-view-switch {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 30px;
        padding: 5px 8px;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        background: #FFFFFF;
        box-shadow: 0 0 10px rgba(187, 203, 215, 0.5);
        border-radius: 5px;
        cursor: pointer;

        label {
          margin: 0;
        }

        span {
          display: block;
        }

        span:last-child {
          text-align: right;
        }

        .custom-switch {
          margin: -3px -4px 0 0;

          .custom-control-label::before {
            width: 2.2rem;
            height: 1.2rem;
            border: 1px solid #adb5bd;
            border-radius: 0.6rem;
            box-shadow: none !important;
          }

          .custom-control-label::after {
            left: calc(-2.25rem + 2px);
            width: calc(1.2rem - 4px);
            height: calc(1.2rem - 4px);
          }

          .custom-control-input:checked~.custom-control-label::after {
            left: calc(-2rem + 2px);
          }

          .custom-control-input:checked~.custom-control-label::before {
            border-color: #00D0B3;
            background-color: #00D0B3;
          }
        }
      }

      .custom-select-control {
        .pg-select-selection {
          background: #FFFFFF !important;
          box-shadow: 0 0 10px rgba(187, 203, 215, 0.5);
          border-radius: 5px;
        }
      }
    }
  }
}

/* items-list-table-confirm */
.items-list-table-confirm {
  .modal-dialog {
    width: 280px;
    max-width: 280px;
    top: 50%;
    margin: -65px auto 0 auto !important;

    .modal-content {
      background-color: #fff !important;
      border-radius: 10px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }

    .btn {
      border-radius: 5px;

      &.btn-primary {
        background-color: #00d1b4;
        border-color: #00d1b4;

        &:hover,
        &:focus,
        &:active {
          background-color: #00b89e !important;
          border-color: #00b89e !important;
        }
      }

      &.btn-outline-danger {
        border: 1px solid #D83C31;
      }
    }
  }
}