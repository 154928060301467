.toast-container .toast {
  opacity: 1;
  border-radius: 10px;
}

.toast-success {
  background-color: #00D0B3;
}

.toast-error {
  background-color: #FF5254;
  box-shadow: 0px 0px 10px rgba(255, 82, 84, 0.25);
}

.toast-error {
    background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='30' height='30' rx='15' fill='%23CF2F3C'/%3E%3Cpath d='M13.5 23.5C13.5 22.6716 14.1716 22 15 22C15.8284 22 16.5 22.6716 16.5 23.5C16.5 24.3284 15.8284 25 15 25C14.1716 25 13.5 24.3284 13.5 23.5ZM15 5C14.1716 5 13.5 5.67157 13.5 6.5V18.5C13.5 19.3284 14.1716 20 15 20C15.8284 20 16.5 19.3284 16.5 18.5V6.5C16.5 5.67157 15.8284 5 15 5Z' fill='white'/%3E%3C/svg%3E%0A");  
}
