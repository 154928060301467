$color-primary: #00D0B3;

.theme-app {
  .bs-datepicker-container {
    padding: 0;
  }

  .bs-datepicker-head {
    background-color: $color-primary;

    bs-datepicker-navigation-view {
      display: flex;
      align-items: center;
      justify-content: space-around;
    }

    button {
      color: #ffffff;
      text-transform: capitalize;

      &.next span,
      &.previous span {
        font-size: 26px;
      }
    }
  }

  .bs-datepicker-predefined-btns {
    button.selected {
      background-color: $color-primary;
      color: #ffffff;
    }
  }

  .bs-datepicker-body {
    table {
      &.days span {
        width: 30px;
        height: 30px;
        line-height: 30px;
      }

      th {
        color: #8D949D;
      }

      td {
        color: #000000;

        span.selected {
          background-color: $color-primary;
          color: #ffffff;
        }

        span.disabled {
          color: #BBCBD7;
          cursor: auto;
        }

        span[class*="select-"] {
          &:after {
            background-color: $color-primary;
          }
        }
      }

      td.selected {
        span {
          background-color: $color-primary;
        }
      }

      td[class*="select-"] {
        span {
          &:after {
            background-color: $color-primary;
          }
        }
      }

      td.week {
        span {
          color: $color-primary;
        }
      }

      td.active-week {
        span {
          &:hover {
            cursor: pointer;
            background-color: $color-primary;
            color: #FEFEFE;
            opacity: 0.5;
            transition: 0s;
          }
        }
      }
    }
  }
}