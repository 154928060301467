$pagination-color:#889099;
$pagination-bg: #ECEEF1;
$pagination-border-color: transparent;

$pagination-active-color: #FFFFFF;
$pagination-active-bg: #00D0B3;
$pagination-active-border-color: transparent;
$pagination-hover-color: #FFFFFF;
$pagination-hover-bg: #00D0B3;
$pagination-hover-border-color: transparent;
$pagination-focus-outline: none;
$pagination-focus-box-shadow: none;
$border-radius: 3px;
$pagination-disabled-color: $pagination-color;
$pagination-disabled-bg: $pagination-bg;
$pagination-disabled-border-color: $pagination-border-color;

.page-link {
  color: $pagination-color;
  background-color: $pagination-bg;
  border-color: $pagination-border-color;
  border-radius: $border-radius;
  font-size: 14px;
  line-height: 10px;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  padding: 6px 10px;
  min-width: 30px;
  font-family: "SF Pro Display";

  &:hover {
    z-index: 2;
    color: $pagination-hover-color;
    text-decoration: none;
    background-color: $pagination-hover-bg;
    border-color: $pagination-hover-border-color;
  }

  &:focus {
    z-index: 2;
    outline: $pagination-focus-outline;
    box-shadow: $pagination-focus-box-shadow;
  }
}

.page-item {
  //   &:first-child {
  //     .page-link {
  //       margin-left: 0;
  //       @include border-left-radius($border-radius);
  //     }
  //   }

  //   &:last-child {
  //     .page-link {
  //       @include border-right-radius($border-radius);
  //     }
  //   }

  &.active .page-link {
    // z-index: 1;
    color: $pagination-active-color;
    background-color: $pagination-active-bg;
    border-color: $pagination-active-border-color;
  }

  &.disabled .page-link {
    color: $pagination-disabled-color;
    pointer-events: none;
    cursor: auto;
    background-color: $pagination-disabled-bg;
    border-color: $pagination-disabled-border-color;
  }
}