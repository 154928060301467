.modal .modal-dialog {
  &.modal-big {
    max-width: calc(100vw - 70px);
    width: calc(100vw - 72px);
    height: calc(100% - 60px);
    margin: 0;
    top: 60px;
    left: 70px;

    .modal-content {
      border: none;
      box-shadow: 0px 0px 10px rgb(0 0 0 / 25%);
      border-radius: 10px 10px 0px 0px;
    }

    @media (max-width: 991px) {
      left: 0;
      max-width: 100vw;
      width: 100vw;
    }

    @media (min-width: 768px) {}
  }

  &.modal-notify {
    @media (min-width: 576px) {
      max-width: 400px;
    }

    .modal-content {
      border-radius: 10px;
    }
  }

  &.modal-dialog-right {
    margin-right: 0;
    margin-left: auto;
    margin-top: 0;
    margin-bottom: 0;

    .modal-content {
      border: none;
    }
  }

  &.modal-categories {
    @media (min-width: 768px) {
      max-width: 50%;
      width: 50%;
    }
  }

  &.modal-dialog-md {
    @media (min-width: 768px) {
      max-width: 760px;
      width: 760px;
    }
  }


  .icon-closed {
    display: inline-block;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 1.4L14.6 0L8 6.6L1.4 0L0 1.4L6.6 8L0 14.6L1.4 16L8 9.4L14.6 16L16 14.6L9.4 8L16 1.4Z' fill='%23878F9F'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transition: background-position 150ms ease, opacity 60ms linear;
    width: 16px;
    height: 16px;
  }
}