.cursor-pointer {
  cursor: pointer !important;
}

.hover-opacity-8{
  &:hover {
    opacity: .8;
  }
}

$prefix : 'mesh';

.mesh {

  &-font-segoe {
      font-family: Segoe UI !important;
  }

  &-text-secondary {
      color: #626262 !important;
  }

  &-hover-opacity:hover {
      opacity: 0.7;
  }

  &-hover-bg:hover {
      background-color: #d3d3d3;
      border-radius: 3px;
  }


  @for $i from 1 through 9 {
      &-font-w-#{$i} {
          font-weight: $i * 100;
      }
  }

  @for $i from 1 through 20 {
      &-scale-#{$i} {
          transform: scale($i/10) !important;
      }
  }

  @for $i from 1 through 70 {

      /*  margins */
      &-mb-#{$i} {
          margin-bottom: #{$i}px !important;
      }

      &-mt-#{$i} {
          margin-top: #{$i}px !important;
      }

      &-my-#{$i} {
          @extend .#{$prefix}-mt-#{$i};
          @extend .#{$prefix}-mb-#{$i};
      }

      &-mr-#{$i} {
          margin-right: #{$i}px !important;
      }

      &-ml-#{$i} {
          margin-left: #{$i}px !important;
      }

      &-mx-#{$i} {
          @extend .#{$prefix}-ml-#{$i};
          @extend .#{$prefix}-mr-#{$i};
      }

      /*  paddings */
      &-pb-#{$i} {
          padding-bottom: #{$i}px !important;
      }

      &-pt-#{$i} {
          padding-top: #{$i}px !important;
      }

      &-py-#{$i} {
          @extend .#{$prefix}-pt-#{$i};
          @extend .#{$prefix}-pb-#{$i};
      }

      &-pr-#{$i} {
          padding-right: #{$i}px !important;
      }

      &-pl-#{$i} {
          padding-left: #{$i}px !important;
      }

      &-px-#{$i} {
          @extend .#{$prefix}-pl-#{$i};
          @extend .#{$prefix}-pr-#{$i};
      }



      /* font-size */
      &-fs-#{$i} {
          font-size: #{$i}px;
      }
  }
}