@import "var";
@import "mixins";
@import '~swiper/dist/css/swiper.min.css';
@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import "assets/fonts/mesh-icons/css/mesh-icons.scss";
@import "~placeholder-loading/src/scss/placeholder-loading";
@import "utils";
@import "./bs-datepicker";
@import "./bs-modal";
@import "./loading";
@import "~ngx-toastr/toastr.css";
@import "./toastr";
@import "./items-table-page";
@import "./bs-paggination";

@font-face {
  font-family: 'Segoe UI';
  src: url('/assets/fonts/SegoeUI/SegoeUI-Light.eot');
  src: local('Segoe UI Light'), local('SegoeUI-Light'),
    url('/assets/fonts/SegoeUI/SegoeUI-Light.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/SegoeUI/SegoeUI-Light.woff') format('woff'),
    url('/assets/fonts/SegoeUI/SegoeUI-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Segoe UI';
  src: url('/assets/fonts/SegoeUI/SegoeUI-BoldItalic.eot');
  src: local('Segoe UI Bold Italic'), local('SegoeUI-BoldItalic'),
    url('/assets/fonts/SegoeUI/SegoeUI-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/SegoeUI/SegoeUI-BoldItalic.woff') format('woff'),
    url('/assets/fonts/SegoeUI/SegoeUI-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Segoe UI';
  src: url('/assets/fonts/SegoeUI/SegoeUI-SemiBold.eot');
  src: local('Segoe UI Semibold'), local('SegoeUI-SemiBold'),
    url('/assets/fonts/SegoeUI/SegoeUI-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/SegoeUI/SegoeUI-SemiBold.woff') format('woff'),
    url('/assets/fonts/SegoeUI/SegoeUI-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Segoe UI';
  src: url('/assets/fonts/SegoeUI/SegoeUI.eot');
  src: local('Segoe UI'), local('SegoeUI'),
    url('/assets/fonts/SegoeUI/SegoeUI.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/SegoeUI/SegoeUI.woff') format('woff'),
    url('/assets/fonts/SegoeUI/SegoeUI.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Segoe UI';
  src: url('/assets/fonts/SegoeUI/SegoeUI-Bold.eot');
  src: local('Segoe UI Bold'), local('SegoeUI-Bold'),
    url('/assets/fonts/SegoeUI/SegoeUI-Bold.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/SegoeUI/SegoeUI-Bold.woff') format('woff'),
    url('/assets/fonts/SegoeUI/SegoeUI-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Segoe UI';
  src: url('/assets/fonts/SegoeUI/SegoeUI-Italic.eot');
  src: local('Segoe UI Italic'), local('SegoeUI-Italic'),
    url('/assets/fonts/SegoeUI/SegoeUI-Italic.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/SegoeUI/SegoeUI-Italic.woff') format('woff'),
    url('/assets/fonts/SegoeUI/SegoeUI-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'SF Text Regular';
  src: url('/assets/fonts/SF/SFUIText-Regular.eot');
  src: url('/assets/fonts/SF/SFUIText-Regular.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/SF/SFUIText-Regular.woff') format('woff'),
    url('/assets/fonts/SF/SFUIText-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('/assets/fonts/SF/SFProDisplay-Medium.eot');
  src: local('SF Pro Display Medium'), local('SFProDisplay-Medium'),
    url('/assets/fonts/SF/SFProDisplay-Medium.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/SF/SFProDisplay-Medium.woff2') format('woff2'),
    url('/assets/fonts/SF/SFProDisplay-Medium.woff') format('woff'),
    url('/assets/fonts/SF/SFProDisplay-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

//@font-face {
//  font-family: 'SF Pro Display';
//  src: url('/assets/fonts/SF/SFProDisplay-Heavy.eot');
//  src: local('SF Pro Display Heavy'), local('SFProDisplay-Heavy'),
//  url('/assets/fonts/SF/SFProDisplay-Heavy.eot?#iefix') format('embedded-opentype'),
//  url('/assets/fonts/SF/SFProDisplay-Heavy.woff2') format('woff2'),
//  url('/assets/fonts/SF/SFProDisplay-Heavy.woff') format('woff'),
//  url('/assets/fonts/SF/SFProDisplay-Heavy.ttf') format('truetype');
//  font-weight: 900;
//  font-style: normal;
//}

//@font-face {
//  font-family: 'SF Pro Display';
//  src: url('/assets/fonts/SF/SFProDisplay-UltralightItalic.eot');
//  src: local('SF Pro Display UltralightItalic'), local('SFProDisplay-UltralightItalic'),
//  url('/assets/fonts/SF/SFProDisplay-UltralightItalic.eot?#iefix') format('embedded-opentype'),
//  url('/assets/fonts/SF/SFProDisplay-UltralightItalic.woff2') format('woff2'),
//  url('/assets/fonts/SF/SFProDisplay-UltralightItalic.woff') format('woff'),
//  url('/assets/fonts/SF/SFProDisplay-UltralightItalic.ttf') format('truetype');
//  font-weight: 200;
//  font-style: italic;
//}

@font-face {
  font-family: 'SF Pro Display';
  src: url('/assets/fonts/SF/SFProDisplay-RegularItalic.eot');
  src: local('SF Pro Display Regular Italic'), local('SFProDisplay-RegularItalic'),
    url('/assets/fonts/SF/SFProDisplay-RegularItalic.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/SF/SFProDisplay-RegularItalic.woff2') format('woff2'),
    url('/assets/fonts/SF/SFProDisplay-RegularItalic.woff') format('woff'),
    url('/assets/fonts/SF/SFProDisplay-RegularItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

//@font-face {
//  font-family: 'SF Pro Display';
//  src: url('/assets/fonts/SF/SFProDisplay-Thin.eot');
//  src: local('SF Pro Display Thin'), local('SFProDisplay-Thin'),
//  url('/assets/fonts/SF/SFProDisplay-Thin.eot?#iefix') format('embedded-opentype'),
//  url('/assets/fonts/SF/SFProDisplay-Thin.woff2') format('woff2'),
//  url('/assets/fonts/SF/SFProDisplay-Thin.woff') format('woff'),
//  url('/assets/fonts/SF/SFProDisplay-Thin.ttf') format('truetype');
//  font-weight: 100;
//  font-style: normal;
//}

//@font-face {
//  font-family: 'SF Pro Display';
//  src: url('/assets/fonts/SF/SFProDisplay-Light.eot');
//  src: local('SF Pro Display Light'), local('SFProDisplay-Light'),
//  url('/assets/fonts/SF/SFProDisplay-Light.eot?#iefix') format('embedded-opentype'),
//  url('/assets/fonts/SF/SFProDisplay-Light.woff2') format('woff2'),
//  url('/assets/fonts/SF/SFProDisplay-Light.woff') format('woff'),
//  url('/assets/fonts/SF/SFProDisplay-Light.ttf') format('truetype');
//  font-weight: 200;
//  font-style: normal;
//}

//@font-face {
//  font-family: 'SF Pro Display';
//  src: url('/assets/fonts/SF/SFProDisplay-BlackItalic.eot');
//  src: local('SF Pro Display Black Italic'), local('SFProDisplay-BlackItalic'),
//  url('/assets/fonts/SF/SFProDisplay-BlackItalic.eot?#iefix') format('embedded-opentype'),
//  url('/assets/fonts/SF/SFProDisplay-BlackItalic.woff2') format('woff2'),
//  url('/assets/fonts/SF/SFProDisplay-BlackItalic.woff') format('woff'),
//  url('/assets/fonts/SF/SFProDisplay-BlackItalic.ttf') format('truetype');
//  font-weight: 900;
//  font-style: italic;
//}

@font-face {
  font-family: 'SF Pro Display';
  src: url('/assets/fonts/SF/SFProDisplay-Bold.eot');
  src: local('SF Pro Display Bold'), local('SFProDisplay-Bold'),
    url('/assets/fonts/SF/SFProDisplay-Bold.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/SF/SFProDisplay-Bold.woff2') format('woff2'),
    url('/assets/fonts/SF/SFProDisplay-Bold.woff') format('woff'),
    url('/assets/fonts/SF/SFProDisplay-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

//@font-face {
//  font-family: 'SF Pro Display';
//  src: url('/assets/fonts/SF/SFProDisplay-Black.eot');
//  src: local('SF Pro Display Black'), local('SFProDisplay-Black'),
//  url('/assets/fonts/SF/SFProDisplay-Black.eot?#iefix') format('embedded-opentype'),
//  url('/assets/fonts/SF/SFProDisplay-Black.woff2') format('woff2'),
//  url('/assets/fonts/SF/SFProDisplay-Black.woff') format('woff'),
//  url('/assets/fonts/SF/SFProDisplay-Black.ttf') format('truetype');
//  font-weight: 900;
//  font-style: normal;
//}

//@font-face {
//  font-family: 'SF Pro Display';
//  src: url('/assets/fonts/SF/SFProDisplay-SemiboldItalic.eot');
//  src: local('SF Pro Display SemiboldItalic'), local('SFProDisplay-SemiboldItalic'),
//  url('/assets/fonts/SF/SFProDisplay-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
//  url('/assets/fonts/SF/SFProDisplay-SemiboldItalic.woff2') format('woff2'),
//  url('/assets/fonts/SF/SFProDisplay-SemiboldItalic.woff') format('woff'),
//  url('/assets/fonts/SF/SFProDisplay-SemiboldItalic.ttf') format('truetype');
//  font-weight: 600;
//  font-style: italic;
//}

//@font-face {
//  font-family: 'SF Pro Display';
//  src: url('/assets/fonts/SF/SFProDisplay-Ultralight.eot');
//  src: local('SF Pro Display Ultralight'), local('SFProDisplay-Ultralight'),
//  url('/assets/fonts/SF/SFProDisplay-Ultralight.eot?#iefix') format('embedded-opentype'),
//  url('/assets/fonts/SF/SFProDisplay-Ultralight.woff2') format('woff2'),
//  url('/assets/fonts/SF/SFProDisplay-Ultralight.woff') format('woff'),
//  url('/assets/fonts/SF/SFProDisplay-Ultralight.ttf') format('truetype');
//  font-weight: 200;
//  font-style: normal;
//}

//@font-face {
//  font-family: 'SF Pro Display';
//  src: url('/assets/fonts/SF/SFProDisplay-LightItalic.eot');
//  src: local('SF Pro Display LightItalic'), local('SFProDisplay-LightItalic'),
//  url('/assets/fonts/SF/SFProDisplay-LightItalic.eot?#iefix') format('embedded-opentype'),
//  url('/assets/fonts/SF/SFProDisplay-LightItalic.woff2') format('woff2'),
//  url('/assets/fonts/SF/SFProDisplay-LightItalic.woff') format('woff'),
//  url('/assets/fonts/SF/SFProDisplay-LightItalic.ttf') format('truetype');
//  font-weight: 200;
//  font-style: italic;
//}
//
//@font-face {
//  font-family: 'SF Pro Display';
//  src: url('/assets/fonts/SF/SFProDisplay-ThinItalic.eot');
//  src: local('SF Pro Display ThinItalic'), local('SFProDisplay-ThinItalic'),
//  url('/assets/fonts/SF/SFProDisplay-ThinItalic.eot?#iefix') format('embedded-opentype'),
//  url('/assets/fonts/SF/SFProDisplay-ThinItalic.woff2') format('woff2'),
//  url('/assets/fonts/SF/SFProDisplay-ThinItalic.woff') format('woff'),
//  url('/assets/fonts/SF/SFProDisplay-ThinItalic.ttf') format('truetype');
//  font-weight: 100;
//  font-style: italic;
//}
//
//@font-face {
//  font-family: 'SF Pro Display';
//  src: url('/assets/fonts/SF/SFProDisplay-MediumItalic.eot');
//  src: local('SF Pro Display MediumItalic'), local('SFProDisplay-MediumItalic'),
//  url('/assets/fonts/SF/SFProDisplay-MediumItalic.eot?#iefix') format('embedded-opentype'),
//  url('/assets/fonts/SF/SFProDisplay-MediumItalic.woff2') format('woff2'),
//  url('/assets/fonts/SF/SFProDisplay-MediumItalic.woff') format('woff'),
//  url('/assets/fonts/SF/SFProDisplay-MediumItalic.ttf') format('truetype');
//  font-weight: 500;
//  font-style: italic;
//}

@font-face {
  font-family: 'SF Pro Display';
  src: url('/assets/fonts/SF/SFProDisplay-Semibold.eot');
  src: local('SF Pro Display Semibold'), local('SFProDisplay-Semibold'),
    url('/assets/fonts/SF/SFProDisplay-Semibold.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/SF/SFProDisplay-Semibold.woff2') format('woff2'),
    url('/assets/fonts/SF/SFProDisplay-Semibold.woff') format('woff'),
    url('/assets/fonts/SF/SFProDisplay-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

//@font-face {
//  font-family: 'SF Pro Display';
//  src: url('/assets/fonts/SF/SFProDisplay-HeavyItalic.eot');
//  src: local('SF Pro Display HeavyItalic'), local('SFProDisplay-HeavyItalic'),
//  url('/assets/fonts/SF/SFProDisplay-HeavyItalic.eot?#iefix') format('embedded-opentype'),
//  url('/assets/fonts/SF/SFProDisplay-HeavyItalic.woff2') format('woff2'),
//  url('/assets/fonts/SF/SFProDisplay-HeavyItalic.woff') format('woff'),
//  url('/assets/fonts/SF/SFProDisplay-HeavyItalic.ttf') format('truetype');
//  font-weight: 900;
//  font-style: italic;
//}

@font-face {
  font-family: 'SF Pro Display';
  src: url('/assets/fonts/SF/SFProDisplay-Regular.eot');
  src: local('SF Pro Display Regular'), local('SFProDisplay-Regular'),
    url('/assets/fonts/SF/SFProDisplay-Regular.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/SF/SFProDisplay-Regular.woff2') format('woff2'),
    url('/assets/fonts/SF/SFProDisplay-Regular.woff') format('woff'),
    url('/assets/fonts/SF/SFProDisplay-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('/assets/fonts/SF/SFProDisplay-BoldItalic.eot');
  src: local('SF Pro Display BoldItalic'), local('SFProDisplay-BoldItalic'),
    url('/assets/fonts/SF/SFProDisplay-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/SF/SFProDisplay-BoldItalic.woff2') format('woff2'),
    url('/assets/fonts/SF/SFProDisplay-BoldItalic.woff') format('woff'),
    url('/assets/fonts/SF/SFProDisplay-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('/assets/fonts/SF/SFProText/SFProText-Regular.eot');
  src: local('SF Pro Text Regular'), local('SFProText-Regular'),
    url('/assets/fonts/SF/SFProText/SFProText-Regular.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/SF/SFProText/SFProText-Regular.woff2') format('woff2'),
    url('/assets/fonts/SF/SFProText/SFProText-Regular.woff') format('woff'),
    url('/assets/fonts/SF/SFProText/SFProText-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('/assets/fonts/SF/SFProText/SFProText-Semibold.eot');
  src: local('SF Pro Text Semibold'), local('SFProText-Semibold'),
    url('/assets/fonts/SF/SFProText/SFProText-Semibold.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/SF/SFProText/SFProText-Semibold.woff2') format('woff2'),
    url('/assets/fonts/SF/SFProText/SFProText-Semibold.woff') format('woff'),
    url('/assets/fonts/SF/SFProText/SFProText-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('/assets/fonts/SF/SFProText/SFProText-Light.eot');
  src: local('SF Pro Text Light'), local('SFProText-Light'),
    url('/assets/fonts/SF/SFProText/SFProText-Light.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/SF/SFProText/SFProText-Light.woff2') format('woff2'),
    url('/assets/fonts/SF/SFProText/SFProText-Light.woff') format('woff'),
    url('/assets/fonts/SF/SFProText/SFProText-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

.fluid-bread {
  background-color: transparent !important;
}

.question-eq {
  position: relative;
  width: 100%;
}

.question-eq .question-eq-list {
  position: relative;
  z-index: 2;
}

.question-eq .question-eq-list__item {
  border: 0;
  padding: 5px;
  margin-bottom: 10px;
  font-family: SF Pro Display, sans-serif;
  text-overflow: ellipsis;
  display: flex;
}

.question-eq .question-eq-list__item__answer {
  padding: 5px;
  width: 50%;
  margin: 5px;
  opacity: 0;
}

.question-eq .question-eq-list__item__equal {
  width: 50%;
}

.question-eq .question-eq-list__item__equal>div {
  padding: 5px;
  margin: 5px;
  border: 1px solid #10cfbd;
}

.question-eq .question-eq-list--ghost {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.question-eq .question-eq-list--ghost__item {
  border-bottom: 1px solid #CCC;
}

.question-eq .question-eq-list--ghost__item__answer {
  opacity: 1;
  border: 1px solid #10cfbd;
}

.question-eq .question-eq-list--ghost__item__equal {
  opacity: 0;
}

.question-eq .question-eq-list__item.draggable {
  .question-eq-list__item__answer {
    opacity: 1;
  }
}

.btn-standard,
.btn-standard:hover {
  cursor: pointer;
  overflow: hidden;
  outline: none;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  -webkit-transition: .2s ease;
  transition: .2s ease;
  font-size: 17px;
  line-height: 23px;
  position: relative;
  padding: 5px 10px;
  box-shadow: unset;
  border: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;

  &.green {
    background-color: #00D0B3;
  }

  &.green-outline {
    background: #FFFFFF;
    border: 1px solid #00D0B3;
    color: #00D0B3;
  }


  &.blue {
    background: #0EBCF2;
  }


}

.flex-fluid {
  flex: 1 1 10px;
}

.start-button {
  margin: 0;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  font: 700 16px/36px Circe, Arial, sans-serif;
  color: #fff;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  -webkit-transition: .2s ease;
  transition: .2s ease;
  position: relative;
  margin-top: 10px;
  height: 36px;
  width: 259px;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: #10cfbd;
  -webkit-box-shadow: 0 18px 50px 0 rgba(16, 207, 189, .32);
  box-shadow: 0 18px 50px 0 rgba(16, 207, 189, .32);

}

/*!
 * FullCalendar v3.1.0 Stylesheet
 * Docs & License: http://fullcalendar.io/
 * (c) 2016 Adam Shaw
 */
.fc {
  direction: ltr;
  text-align: left;
}

.fc-rtl {
  text-align: right;
}

body .fc {
  /* extra precedence to overcome jqui */
  font-size: 1em;
}

/* Colors
--------------------------------------------------------------------------------------------------*/
.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-popover {
  border-color: #ddd;
}

.fc-unthemed .fc-popover {
  background-color: #FFFFFF;
}

.fc-unthemed .fc-divider,
.fc-unthemed .fc-popover .fc-header {
  background: #E3E3E3;
}

.fc-unthemed .fc-popover .fc-header .fc-close {
  color: #666666;
}

.fc-unthemed .fc-today {
  background: #F5F5F5;
}

.fc-highlight {
  /* when user is selecting cells */
  background: #bce8f1;
  opacity: .3;
  filter: alpha(opacity=30);
  /* for IE */
}

.fc-bgevent {
  /* default look for background events */
  background: #8fdf82;
  opacity: .3;
  filter: alpha(opacity=30);
  /* for IE */
}

.fc-nonbusiness {
  /* default look for non-business-hours areas */
  /* will inherit .fc-bgevent's styles */
  background: #d7d7d7;
}

/* Icons (inline elements with styled text that mock arrow icons)
--------------------------------------------------------------------------------------------------*/
.fc-icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1em;
  font-size: 1em;
  text-align: center;
  overflow: hidden;
  font-family: "Courier New", Courier, monospace;
  /* don't allow browser text-selection */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/*
Acceptable font-family overrides for individual icons:
	"Arial", sans-serif
	"Times New Roman", serif

NOTE: use percentage font sizes or else old IE chokes
*/
.fc-icon:after {
  position: relative;
  margin: 0 -1em;
  /* ensures character will be centered, regardless of width */
}

.fc-icon-left-single-arrow:after {
  content: "\02039";
  font-weight: bold;
  font-size: 200%;
  top: -7%;
  left: 3%;
}

.fc-icon-right-single-arrow:after {
  content: "\0203A";
  font-weight: bold;
  font-size: 200%;
  top: -7%;
  left: -3%;
}

.fc-icon-left-double-arrow:after {
  content: "\000AB";
  font-size: 160%;
  top: -7%;
}

.fc-icon-right-double-arrow:after {
  content: "\000BB";
  font-size: 160%;
  top: -7%;
}

.fc-icon-left-triangle:after {
  content: "\25C4";
  font-size: 125%;
  top: 3%;
  left: -2%;
}

.fc-icon-right-triangle:after {
  content: "\25BA";
  font-size: 125%;
  top: 3%;
  left: 2%;
}

.fc-icon-down-triangle:after {
  content: "\25BC";
  font-size: 125%;
  top: 2%;
}

.fc-icon-x:after {
  content: "\000D7";
  font-size: 200%;
  top: 6%;
}

/* Buttons (styled <button> tags, normalized to work cross-browser)
--------------------------------------------------------------------------------------------------*/
.fc button {
  border-radius: 4px !important;
  box-sizing: border-box;
  border-width: 1px;
  background-color: transparent;
  font-size: 9px;
  font-weight: 500;
  outline: none;
  padding: 5px;
  border-color: #939393;
  color: #2C2C2C;
  -webkit-transition: all 150ms linear;
  -moz-transition: all 150ms linear;
  -o-transition: all 150ms linear;
  -ms-transition: all 150ms linear;
  transition: all 150ms linear;
  margin-right: 5px;
}

.fc-toolbar.fc-header-toolbar {
  margin-bottom: 0;
}

.fc button:hover,
.fc button:focus,
.fc button:active,
.fc button.active,
.fc button:active:focus,
.fc button:active:hover,
.open>.fc button.dropdown-toggle,
.open>.fc button.dropdown-toggle:focus,
.open>.fc button.dropdown-toggle:hover {
  background-color: #66615B;
  color: rgba(255, 255, 255, 0.85);
  border-color: #66615B;
}

.fc button:hover .caret,
.fc button:focus .caret,
.fc button:active .caret,
.fc button.active .caret,
.fc button:active:focus .caret,
.fc button:active:hover .caret,
.open>.fc button.dropdown-toggle .caret,
.open>.fc button.dropdown-toggle:focus .caret,
.open>.fc button.dropdown-toggle:hover .caret {
  border-top-color: rgba(255, 255, 255, 0.85);
}

.fc button.disabled,
.fc button.disabled:hover,
.fc button.disabled:focus,
.fc button.disabled.focus,
.fc button.disabled:active,
.fc button.disabled.active,
.fc button:disabled,
.fc button:disabled:hover,
.fc button:disabled:focus,
.fc button:disabled.focus,
.fc button:disabled:active,
.fc button:disabled.active,
.fc button[disabled],
.fc button[disabled]:hover,
.fc button[disabled]:focus,
.fc button[disabled].focus,
.fc button[disabled]:active,
.fc button[disabled].active,
fieldset[disabled] .fc button,
fieldset[disabled] .fc button:hover,
fieldset[disabled] .fc button:focus,
fieldset[disabled] .fc button.focus,
fieldset[disabled] .fc button:active,
fieldset[disabled] .fc button.active {
  background-color: transparent;
  border-color: #66615B;
}

.fc button.btn-fill {
  color: #FFFFFF;
  background-color: #66615B;
  opacity: 1;
  filter: alpha(opacity=100);
}

.fc button.btn-fill:hover,
.fc button.btn-fill:focus,
.fc button.btn-fill:active,
.fc button.btn-fill.active,
.open>.fc button.btn-fill.dropdown-toggle {
  background-color: #484541;
  color: #FFFFFF;
  border-color: #484541;
}

.fc button.btn-fill .caret {
  border-top-color: #FFFFFF;
}

.fc button.btn-simple:hover,
.fc button.btn-simple:focus,
.fc button.btn-simple:active,
.fc button.btn-simple.active,
.open>.fc button.btn-simple.dropdown-toggle {
  background-color: transparent;
  color: #484541;
}

.fc button.btn-simple .caret {
  border-top-color: #FFFFFF;
}

.fc button .caret {
  border-top-color: #66615B;
}

.fc button:hover,
.fc button:focus {
  outline: 0 !important;
}

.fc button:active,
.fc button.active,
.open>.fc button.dropdown-toggle {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0 !important;
}

.fc button.btn-icon {
  border-radius: 25px;
  padding: 6px 10px;
}

.fc button.btn-icon i {
  margin-right: 0px;
}

/* Firefox has an annoying inner border */
.fc button::-moz-focus-inner {
  margin: 0;
  padding: 0;
}

.fc-state-default {
  /* non-theme */
  border: 1px solid;
}

/* icons in buttons */
.fc button .fc-icon {
  /* non-theme */
  position: relative;
  top: -0.05em;
  /* seems to be a good adjustment across browsers */
  margin: 0 .2em;
  vertical-align: middle;
}

/*
  button states
  borrowed from twitter bootstrap (http://twitter.github.com/bootstrap/)
*/
.fc-state-hover,
.fc-state-active,
.fc-state-disabled {
  color: #333333;
  background-color: #e6e6e6;
}

.fc button[disabled],
.fc button[disabled]:focus,
.fc button[disabled]:hover {
  background-color: #E3E3E3;
  border-color: #E3E3E3;
  cursor: default;
  opacity: 0.65;
  filter: alpha(opacity=65);
  color: #66615b;
}

.fc-state-hover {
  background-color: #F5F5F5;
}

.fc .fc-state-active,
.fc .fc-state-active:focus,
.fc .fc-state-active:hover,
.fc .fc-state-active:active:focus,
.fc .fc-state-active:active:hover,
.fc .fc-state-active:active {
  background-color: #66615B;
  color: #FFFFFF;
}

/* Buttons Groups
--------------------------------------------------------------------------------------------------*/
.fc-button-group {
  display: inline-block;
}

/*
every button that is not first in a button group should scootch over one pixel and cover the
previous button's border...
*/
.fc .fc-button-group>* {
  /* extra precedence b/c buttons have margin set to zero */
  float: left;
  margin: 0 5px 0 0;
}

.fc .fc-button-group> :first-child {
  /* same */
  margin-left: 0;
}

/* Popover
--------------------------------------------------------------------------------------------------*/
.fc-popover {
  position: absolute;
  -webkit-box-shadow: 0 2px rgba(17, 16, 15, 0.1), 0 2px 10px rgba(17, 16, 15, 0.1);
  box-shadow: 0 2px rgba(17, 16, 15, 0.1), 0 2px 10px rgba(17, 16, 15, 0.1);
}

.fc-popover .fc-header {
  /* TODO: be more consistent with fc-head/fc-body */
  padding: 2px 4px;
}

.fc-popover .fc-header .fc-title {
  margin: 0 2px;
}

.fc-popover .fc-header .fc-close {
  cursor: pointer;
}

.fc-ltr .fc-popover .fc-header .fc-title,
.fc-rtl .fc-popover .fc-header .fc-close {
  float: left;
}

.fc-rtl .fc-popover .fc-header .fc-title,
.fc-ltr .fc-popover .fc-header .fc-close {
  float: right;
}

/* unthemed */
.fc-unthemed .fc-popover {
  border-width: 1px;
  border-style: solid;
}

.fc-unthemed .fc-popover .fc-header .fc-close {
  font-size: .9em;
  padding-right: 5px;
  margin-top: 0;

  &::after {
    top: 0;
  }
}

/* jqui themed */
.fc-popover>.ui-widget-header+.ui-widget-content {
  border-top: 0;
  /* where they meet, let the header have the border */
}

/* Misc Reusable Components
--------------------------------------------------------------------------------------------------*/
.fc-divider {
  border-style: solid;
  border-width: 1px;
}

hr.fc-divider {
  height: 0;
  margin: 0;
  padding: 0 0 2px;
  /* height is unreliable across browsers, so use padding */
  border-width: 1px 0;
}

.fc-clear {
  clear: both;
}

.fc-bg,
.fc-bgevent-skeleton,
.fc-highlight-skeleton,
.fc-helper-skeleton {
  /* these element should always cling to top-left/right corners */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.fc-bg {
  bottom: 0;
  /* strech bg to bottom edge */
}

.fc-bg table {
  height: 100%;
  /* strech bg to bottom edge */
}

/* Tables
--------------------------------------------------------------------------------------------------*/
.fc table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 1em;
  /* normalize cross-browser */
}

.fc th {
  text-align: right;
  border-bottom-width: 1px;
  font-size: 0.9em;
  text-transform: uppercase;
  color: #9A9A9A;
  font-weight: 400;
  padding-bottom: 3px;
}

.fc td {
  border-style: solid;
  border-width: 1px;
  padding: 0;
  vertical-align: top;
}

.fc td.fc-today {
  border-style: double;
  /* overcome neighboring borders */
}

.fc .fc-widget-header {
  border: 0;
}

.fc .fc-head .fc-widget-header {
  margin-top: 15px;
}

/* Fake Table Rows
--------------------------------------------------------------------------------------------------*/
.fc .fc-row {
  /* extra precedence to overcome themes w/ .ui-widget-content forcing a 1px border */
  /* no visible border by default. but make available if need be (scrollbar width compensation) */
  border-style: solid;
  border-width: 0;
}

.fc-row table {
  /* don't put left/right border on anything within a fake row.
     the outer tbody will worry about this */
  border-left: 0 hidden transparent;
  border-right: 0 hidden transparent;
  /* no bottom borders on rows */
  border-bottom: 0 hidden transparent;
}

.fc-row:first-child table {
  border-top: 0 hidden transparent;
  /* no top border on first row */
}

/* Day Row (used within the header and the DayGrid)
--------------------------------------------------------------------------------------------------*/
.fc-row {
  position: relative;
}

.fc-row .fc-bg {
  z-index: 1;
}

/* highlighting cells & background event skeleton */
.fc-row .fc-bgevent-skeleton,
.fc-row .fc-highlight-skeleton {
  bottom: 0;
  /* stretch skeleton to bottom of row */
}

.fc-row .fc-bgevent-skeleton table,
.fc-row .fc-highlight-skeleton table {
  height: 100%;
  /* stretch skeleton to bottom of row */
}

.fc-row .fc-highlight-skeleton td,
.fc-row .fc-bgevent-skeleton td {
  border-color: transparent;
}

.fc-row .fc-bgevent-skeleton {
  z-index: 2;
}

.fc-row .fc-highlight-skeleton {
  z-index: 3;
}

/*
row content (which contains day/week numbers and events) as well as "helper" (which contains
temporary rendered events).
*/
.fc-row .fc-content-skeleton {
  position: relative;
  z-index: 4;
  padding-bottom: 2px;
  /* matches the space above the events */
}

.fc-row .fc-helper-skeleton {
  z-index: 5;
}

.fc-row .fc-content-skeleton td,
.fc-row .fc-helper-skeleton td {
  /* see-through to the background below */
  background: none;
  /* in case <td>s are globally styled */
  border-color: transparent;
  /* don't put a border between events and/or the day number */
  border-bottom: 0;
}

.fc-row .fc-content-skeleton tbody td,
.fc-row .fc-helper-skeleton tbody td {
  /* don't put a border between event cells */
  border-top: 0;
}

/* Scrolling Container
--------------------------------------------------------------------------------------------------*/
.fc-scroller {
  /* this class goes on elements for guaranteed vertical scrollbars */
  overflow-y: scroll;
  overflow-x: hidden;
}

.fc-scroller>* {
  /* we expect an immediate inner element */
  position: relative;
  /* re-scope all positions */
  width: 100%;
  /* hack to force re-sizing this inner element when scrollbars appear/disappear */
  overflow: hidden;
  /* don't let negative margins or absolute positioning create further scroll */
}

/* Global Event Styles
--------------------------------------------------------------------------------------------------*/
.fc-event {
  position: relative;
  /* for resize handle and other inner positioning */
  display: block;
  /* make the <a> tag block */
  font-size: .85em;
  line-height: 1.3;
  border-radius: 2px;
  background-color: rgba(122, 158, 159, 0.2);
  /* default BACKGROUND color */
  font-weight: normal;
  /* undo jqui's ui-widget-header bold */
}

.fc-event.event-azure {
  background-color: rgba(104, 179, 200, 0.2);
}

.fc-event.event-green {
  background-color: rgba(122, 194, 154, 0.2);
}

.fc-event.event-orange {
  background-color: rgba(243, 187, 69, 0.2);
}

.fc-event.event-red {
  background-color: rgba(235, 94, 40, 0.2);
}

/* overpower some of bootstrap's and jqui's styles on <a> tags */
.fc-event,
.fc-event:hover,
.ui-widget .fc-event {
  color: #333333;
  /* default TEXT color */
  text-decoration: none;
  /* if <a> has an href */
}

.fc-event[href],
.fc-event.fc-draggable {
  cursor: pointer;
  /* give events with links and draggable events a hand mouse pointer */
}

.fc-not-allowed,
.fc-not-allowed .fc-event {
  /* to override an event's custom cursor */
  cursor: not-allowed;
}

.fc-event .fc-bg {
  /* the generic .fc-bg already does position */
  z-index: 1;
  background: #FFFFFF;
  opacity: .25;
  filter: alpha(opacity=25);
  /* for IE */
}

.fc-event .fc-content {
  position: relative;
  z-index: 2;
}

.fc-event .fc-resizer {
  position: absolute;
  z-index: 3;
}

/* Horizontal Events
--------------------------------------------------------------------------------------------------*/
/* events that are continuing to/from another week. kill rounded corners and butt up against edge */
.fc-ltr .fc-h-event.fc-not-start,
.fc-rtl .fc-h-event.fc-not-end {
  margin-left: 0;
  border-left-width: 0;
  padding-left: 1px;
  /* replace the border with padding */
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.fc-ltr .fc-h-event.fc-not-end,
.fc-rtl .fc-h-event.fc-not-start {
  margin-right: 0;
  border-right-width: 0;
  padding-right: 1px;
  /* replace the border with padding */
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/* resizer */
.fc-h-event .fc-resizer {
  /* positioned it to overcome the event's borders */
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
  width: 5px;
}

/* left resizer  */
.fc-ltr .fc-h-event .fc-start-resizer,
.fc-ltr .fc-h-event .fc-start-resizer:before,
.fc-ltr .fc-h-event .fc-start-resizer:after,
.fc-rtl .fc-h-event .fc-end-resizer,
.fc-rtl .fc-h-event .fc-end-resizer:before,
.fc-rtl .fc-h-event .fc-end-resizer:after {
  right: auto;
  /* ignore the right and only use the left */
  cursor: w-resize;
}

/* right resizer */
.fc-ltr .fc-h-event .fc-end-resizer,
.fc-ltr .fc-h-event .fc-end-resizer:before,
.fc-ltr .fc-h-event .fc-end-resizer:after,
.fc-rtl .fc-h-event .fc-start-resizer,
.fc-rtl .fc-h-event .fc-start-resizer:before,
.fc-rtl .fc-h-event .fc-start-resizer:after {
  left: auto;
  /* ignore the left and only use the right */
  cursor: e-resize;
}

.fc-day-number {
  float: left !important;
}

/* DayGrid events
----------------------------------------------------------------------------------------------------
We use the full "fc-day-grid-event" class instead of using descendants because the event won't
be a descendant of the grid when it is being dragged.
*/
a.fc-day-grid-event,
a.fc-more {
  margin: 2px 0 0 2px;
  border: 0;
  white-space: nowrap;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  /* spacing between events and edges */
  padding: 4px 0 4px 7px;

  &.fc-event.event-status {
    font-size: 13px;
    background-color: #10CFBD;
    color: white;

    &.event-status-take {
      background-color: #48B0F7;
    }
  }
}

@media (min-width: 768px) {

  .fc-day-top,
  .fc-day-number {
    height: 1px;
  }

  a.fc-day-grid-event,
  a.fc-more {
    margin-left: 20px;
  }
}

.fc-day-grid-event .fc-content {
  /* force events to be one-line tall */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fc-day-grid-event .fc-time {
  font-weight: bold;
}

.fc-day-grid-event .fc-resizer {
  /* enlarge the default hit area */
  left: -3px;
  right: -3px;
  width: 7px;
}

.fc-more-cell {
  text-align: right;
}

/* Event Limiting
--------------------------------------------------------------------------------------------------*/
/* "more" link that represents hidden events */
a.fc-more {
  font-size: .85em;
  cursor: pointer;
  text-decoration: underline !important;
}

a.fc-more:hover {
  text-decoration: none !important;
}

.fc-limited {
  /* rows and cells that are hidden because of a "more" link */
  display: none;
}

/* popover that appears when "more" link is clicked */
.fc-day-grid .fc-row {
  z-index: 1;
  /* make the "more" popover one higher than this */
}

.fc-more-popover {
  z-index: 2;
  width: 220px;
}

.fc-more-popover .fc-event-container {
  padding: 10px;
}

/* Toolbar
--------------------------------------------------------------------------------------------------*/
.fc-toolbar {
  flex-direction: row-reverse;
  justify-content: flex-end;
  text-align: center;
  margin-bottom: 1em;
}

.fc-toolbar .fc-left {
  float: left;
  min-width: 150px;
}

.small-calendar .fc-dayGrid-view .fc-body .fc-row {
  min-height: 2em;
}

.fc-toolbar .fc-right {
  float: right;
  padding-right: 20px;
}

.fc-toolbar .fc-center {
  display: inline-block;
}

/* the things within each left/right/center section */
.fc .fc-toolbar>*>* {
  /* extra precedence to override button border margins */
  float: left;
  margin-left: .75em;
}

/* the first thing within each left/center/right section */
.fc .fc-toolbar>*> :first-child {
  /* extra precedence to override button border margins */
  margin-left: 0;
}

/* title text */
.fc-toolbar h2 {
  margin: 0;
  font-size: 15px;
}

/* button layering (for border precedence) */
.fc-toolbar button {
  position: relative;
}

.fc-toolbar .fc-state-hover,
.fc-toolbar .ui-state-hover {
  z-index: 2;
}

.fc-toolbar .fc-state-down {
  z-index: 3;
}

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active {
  z-index: 4;
}

.fc-toolbar button:focus {
  z-index: 5;
}

/* View Structure
--------------------------------------------------------------------------------------------------*/
/* undo twitter bootstrap's box-sizing rules. normalizes positioning techniques */
/* don't do this for the toolbar because we'll want bootstrap to style those buttons as some pt */
.fc-view-container *,
.fc-view-container *:before,
.fc-view-container *:after {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.fc-view,
.fc-view>table {
  /* so dragged elements can be above the view's main element */
  position: relative;
  z-index: 1;
}

/* BasicView
--------------------------------------------------------------------------------------------------*/
/* day row structure */
.fc-basicWeek-view .fc-content-skeleton,
.fc-basicDay-view .fc-content-skeleton {
  /* we are sure there are no day numbers in these views, so... */
  padding-top: 1px;
  /* add a pixel to make sure there are 2px padding above events */
  padding-bottom: 1em;
  /* ensure a space at bottom of cell for user selecting/clicking */
}

.fc-basic-view .fc-body .fc-row {
  min-height: 4em;
  /* ensure that all rows are at least this tall */
}

/* a "rigid" row will take up a constant amount of height because content-skeleton is absolute */
.fc-row.fc-rigid {
  overflow: hidden;
}

.fc-row.fc-rigid .fc-content-skeleton {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

/* week and day number styling */
.fc-basic-view .fc-week-number,
.fc-basic-view .fc-day-number {
  padding: 0 2px;
}

.fc-basic-view td.fc-week-number span,
.fc-basic-view td.fc-day-number {
  padding: 8px;
}

.fc-basic-view .fc-week-number {
  text-align: center;
}

.fc-basic-view .fc-week-number span {
  /* work around the way we do column resizing and ensure a minimum width */
  display: inline-block;
  min-width: 1.25em;
}

.fc-ltr .fc-basic-view .fc-day-number {
  text-align: right;
}

.fc-rtl .fc-basic-view .fc-day-number {
  text-align: left;
}

.fc-other-month {
  background-color: #F3F6FA;
  color: #97A3B4;
}

.fc-day-number {
  color: #AAB2C0;
}

.fc-day-number.fc-other-month {
  opacity: 0.3;
  filter: alpha(opacity=30);
  /* for IE */
  /* opacity with small font can sometimes look too faded
     might want to set the 'color' property instead
     making day-numbers bold also fixes the problem */
}

/* AgendaView all-day area
--------------------------------------------------------------------------------------------------*/
.fc-agenda-view .fc-day-grid {
  position: relative;
  z-index: 2;
  /* so the "more.." popover will be over the time grid */
}

.fc-agenda-view .fc-day-grid .fc-row {
  min-height: 3em;
  /* all-day section will never get shorter than this */
}

.fc-agenda-view .fc-day-grid .fc-row .fc-content-skeleton {
  padding-top: 1px;
  /* add a pixel to make sure there are 2px padding above events */
  padding-bottom: 1em;
  /* give space underneath events for clicking/selecting days */
}

/* TimeGrid axis running down the side (for both the all-day area and the slot area)
--------------------------------------------------------------------------------------------------*/
.fc .fc-axis {
  /* .fc to overcome default cell styles */
  vertical-align: middle;
  padding: 0 4px;
  white-space: nowrap;
}

.fc-ltr .fc-axis {
  text-align: right;
}

.fc-rtl .fc-axis {
  text-align: left;
}

.ui-widget td.fc-axis {
  font-weight: normal;
  /* overcome jqui theme making it bold */
}

/* TimeGrid Structure
--------------------------------------------------------------------------------------------------*/
.fc-time-grid-container,
.fc-time-grid {
  /* so slats/bg/content/etc positions get scoped within here */
  position: relative;
  z-index: 1;
}

.fc-time-grid {
  min-height: 100%;
  /* so if height setting is 'auto', .fc-bg stretches to fill height */
}

.fc-time-grid table {
  /* don't put outer borders on slats/bg/content/etc */
  border: 0 hidden transparent;
}

.fc-time-grid>.fc-bg {
  z-index: 1;
}

.fc-time-grid .fc-slats,
.fc-time-grid>hr {
  /* the <hr> AgendaView injects when grid is shorter than scroller */
  position: relative;
  z-index: 2;
}

.fc-time-grid .fc-bgevent-skeleton,
.fc-time-grid .fc-content-skeleton {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.fc-time-grid .fc-bgevent-skeleton {
  z-index: 3;
}

.fc-time-grid .fc-highlight-skeleton {
  z-index: 4;
}

.fc-time-grid .fc-content-skeleton {
  z-index: 5;
}

.fc-time-grid .fc-helper-skeleton {
  z-index: 6;
}

/* TimeGrid Slats (lines that run horizontally)
--------------------------------------------------------------------------------------------------*/
.fc-time-grid .fc-slats td {
  height: 1.5em;
  border-bottom: 0;
  /* each cell is responsible for its top border */
}

.fc-time-grid .fc-slats .fc-minor td {
  border-top-style: dotted;
}

.fc-time-grid .fc-slats .ui-widget-content {
  /* for jqui theme */
  background: none;
  /* see through to fc-bg */
}

/* TimeGrid Highlighting Slots
--------------------------------------------------------------------------------------------------*/
.fc-time-grid .fc-highlight-container {
  /* a div within a cell within the fc-highlight-skeleton */
  position: relative;
  /* scopes the left/right of the fc-highlight to be in the column */
}

.fc-time-grid .fc-highlight {
  position: absolute;
  left: 0;
  right: 0;
  /* top and bottom will be in by JS */
}

/* TimeGrid Event Containment
--------------------------------------------------------------------------------------------------*/
.fc-time-grid .fc-event-container,
.fc-time-grid .fc-bgevent-container {
  /* a div within a cell within the fc-bgevent-skeleton */
  position: relative;
}

.fc-ltr .fc-time-grid .fc-event-container {
  /* space on the sides of events for LTR (default) */
  margin: 0 2.5% 0 2px;
}

.fc-rtl .fc-time-grid .fc-event-container {
  /* space on the sides of events for RTL */
  margin: 0 2px 0 2.5%;
}

.fc-time-grid .fc-event,
.fc-time-grid .fc-bgevent {
  position: absolute;
  z-index: 1;
  /* scope inner z-index's */
}

.fc-time-grid .fc-bgevent {
  /* background events always span full width */
  left: 0;
  right: 0;
}

/* Generic Vertical Event
--------------------------------------------------------------------------------------------------*/
.fc-v-event.fc-not-start {
  /* events that are continuing from another day */
  /* replace space made by the top border with padding */
  border-top-width: 0;
  padding-top: 1px;
  /* remove top rounded corners */
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.fc-v-event.fc-not-end {
  /* replace space made by the top border with padding */
  border-bottom-width: 0;
  padding-bottom: 1px;
  /* remove bottom rounded corners */
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

/* TimeGrid Event Styling
----------------------------------------------------------------------------------------------------
We use the full "fc-time-grid-event" class instead of using descendants because the event won't
be a descendant of the grid when it is being dragged.
*/
.fc-time-grid-event {
  overflow: hidden;
  /* don't let the bg flow over rounded corners */
}

.fc-time-grid-event .fc-time,
.fc-time-grid-event .fc-title {
  padding: 0 1px;
}

.fc-time-grid-event .fc-time {
  font-size: .85em;
  white-space: nowrap;
}

/* short mode, where time and title are on the same line */
.fc-time-grid-event.fc-short .fc-content {
  /* don't wrap to second line (now that contents will be inline) */
  white-space: nowrap;
}

.fc-time-grid-event.fc-short .fc-time,
.fc-time-grid-event.fc-short .fc-title {
  /* put the time and title on the same line */
  display: inline-block;
  vertical-align: top;
}

.fc-time-grid-event.fc-short .fc-time span {
  display: none;
  /* don't display the full time text... */
}

.fc-time-grid-event.fc-short .fc-time:before {
  content: attr(data-start);
  /* ...instead, display only the start time */
}

.fc-time-grid-event.fc-short .fc-time:after {
  content: "\000A0-\000A0";
  /* seperate with a dash, wrapped in nbsp's */
}

.fc-time-grid-event.fc-short .fc-title {
  font-size: .85em;
  /* make the title text the same size as the time */
  padding: 0;
  /* undo padding from above */
}

/* resizer */
.fc-time-grid-event .fc-resizer {
  left: 0;
  right: 0;
  bottom: 0;
  height: 8px;
  overflow: hidden;
  line-height: 8px;
  font-size: 11px;
  font-family: monospace;
  text-align: center;
  cursor: s-resize;
}

.fc-time-grid-event .fc-resizer:after {
  content: "=";
}

.card-calendar .content {
  padding: 0 !important;
}

.card-calendar .fc-toolbar {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.card-calendar .fc td:first-child {
  border-left: 0;
}

.card-calendar .fc td:last-child {
  border-right: 0;
}

.card-calendar .fc-basic-view td:last-child.fc-week-number span,
.card-calendar .fc-basic-view td:last-child.fc-day-number {
  padding-right: 20px;
}

.card-calendar .fc .fc-day-header:last-child {
  padding-right: 15px;
}

.card-calendar .fc .fc-row:last-child td {
  border-bottom: 0;
}

.card-calendar .fc .fc-body .fc-widget-content {
  border-bottom: 0;
}

.small-calendar {
  border-top: 1px solid #e6e6e6;
  padding: 10px;
  background: #FFF;
}

.page-widget {
  margin-bottom: 10px;

  .rating-widget .tab-content .tab-pane {
    padding-bottom: 0px !important;
  }

  pg-tab-body .row {
    max-height: 400px !important;
    overflow: auto !important;
  }
}

.corner-badge {
  position: absolute;
  height: 80px;
  width: 80px;
  top: -40px;
  right: -40px;
  transform: rotate(45deg);
  display: flex;
  justify-content: center;
  border-radius: 0;
  color: white;
  font-size: 13px;

  span {
    align-self: flex-end;
  }

  &.new {
    background-color: #27cebc;
    z-index: 2;
  }

  &.hit {
    background-color: #f7554e;
    z-index: 3;
  }

  &.recommend {
    background-color: #41aef9;
    z-index: 1;
  }
}

.overflow-hidden {
  overflow: hidden;
}

body#body .ngx-position-absolute.ngx-overlay {
  z-index: 500 !important;
}

.dashboard-collapse {

  &.gray-title {
    pg-collapse.card.card-default>.card-header>h4>a {
      color: #626262 !important;

      &::after,
      &:hover::after,
      &:hover,
      &.collapsed::after,
      &.collapced:hover::after {
        color: #626262 !important;
      }
    }
  }

  &.full-height {
    >pg-collapseset>.card-group {
      height: 100%;

      >pg-collapse {
        display: flex;

        .collapse {
          flex-grow: 1;

          >.card-body {
            height: 100%;
          }
        }
      }
    }
  }

  pg-collapse.card.card-default,
  .pg-collapse.card.card-default {
    border: none;
    background: none;

    >.collapse>.card-body {
      padding: 0;
    }

    >.card-header {

      margin-bottom: 0;
      padding: 0;
      font-size: 24px;
      line-height: 29px;

      >h4>a {
        display: inline-block;
        position: relative;
        color: #00D0B3 !important;
        width: auto;
        font-family: SF Pro Display, serif;
        text-transform: capitalize;
        font-size: 17px;
        padding-right: 40px;
        padding-top: 0;
        opacity: 1;

        @media (min-width: 768px) and (max-width: 1023px) {
          font-size: 24px;
        }

        &::after,
        &:hover::after {
          color: #00D0B3 !important;
          content: '▼';
          font-size: 13px;
          top: 25%;
        }

        &:hover {
          color: #00D0B3 !important;
        }

        &.collapsed::after,
        &.collapced:hover::after {
          color: #00D0B3 !important;
          content: '►';
          top: 25%;
        }
      }
    }
  }
}

body .card .card-header {
  display: flex;
  justify-content: space-between;
  min-height: 0;
  min-height: 120px;
  padding: 16px 20px 14px 20px;

  .card-title {
    text-transform: none;
  }
}

.bg-gray {
  background-color: #939393;
}

.dashboard-container .widget-title>.green {
  font-family: SF Pro Display, serif;
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  color: #00D0B3;

  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 24px;
  }
}

.bg-green {
  background-color: #00D0B3 !important;
}


tabset.custom-bs-tabset {
  ul.nav-tabs {
    display: flex;
    border: none;
    flex-shrink: 0;

    li.nav-item {
      flex: 1;
      text-align: center;

      border-bottom: 1px solid #BFBFBF;

      a {
        color: #BFBFBF;
        border: none;
        font-size: 17px;
        letter-spacing: 0;
        font-family: SF Pro Display;
        text-transform: none;
      }

      &.active {
        a {
          color: #00D0B3;
          border: none;
          background: none;
        }

        border-bottom:1px solid #00D0B3;
      }
    }
  }

  .tab-content {
    padding: 0;
    display: flex;
    flex-direction: column;
  }

}

.text-custom-dark {
  color: #333333 !important;
}

.text-custom-light-dark {
  color: #797979 !important;
}

.title-collapse {
  color: #00D0B3 !important;

  &:after {
    color: #00D0B3 !important;
    content: '▼';
    font-size: 13px;
    top: 25%;
  }

  &.collapsed:after {
    color: #00D0B3 !important;
    content: '►';
    top: 25%;
  }
}

.collapse {}

.widget-9 .card-body,
.widget-9 .card-header {
  overflow: hidden;
}

.widget-9 .slide-block {
  opacity: 1;
  transform: translateY(0);
}

.card-title {
  opacity: 1;
  transform: translateY(0);
}

@for $i from 1 through 12 {
  .col-6:nth-child(#{$i}) {
    .widget-9 {

      .card-title,
      .slide-block {
        animation-name: slideUp;
        animation-duration: 5s;
        animation-timing-function: ease-in;
        animation-iteration-count: infinite;
        animation-delay: $i * 150ms;
      }
    }
  }
}

@keyframes slideUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  15% {
    opacity: 0;
    transform: translateY(-300px);
  }

  16% {
    opacity: 0;
    transform: translateY(300px);
  }

  31% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.mh-1 {
  min-height: 1px !important;
}

.mw-1 {
  min-height: 1px !important;
}

.opacity-01 {
  opacity: 0.1;
}

.doc-card-header {
  display: grid !important;
  grid-template-columns: 400px 1fr 1fr;
  gap: 20px !important;
  min-height: 0;
  height: 100px;
  padding: 16px 20px 0 20px
}

.no-border>div {
  border: none !important;
}